<template>
    <content-loader :height="40" secondaryColor="#ccd8ff" primaryColor="#e7ecff">
        <rect x="5" y="5" rx="0" ry="0" width="30" height="30" />
        <rect x="55" y="5" rx="0" ry="0" width="250" height="8" />
        <rect x="55" y="18" rx="0" ry="0" width="300" height="5" />
        <rect x="55" y="26" rx="0" ry="0" width="300" height="5" />
    </content-loader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';
    export default {
        components : {
            ContentLoader
        }
    }
</script>
