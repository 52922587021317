<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <section class="notification-section section-gap-30">
                <div class="section-title-wrap">
                    <h2 class="section-title text-capitalize">{{ $t("recent notifications")}}</h2>
                </div>
                <template v-if="isContentLoading">
                    <ul class="notification-list">
                            <NotificationLoader  v-for="index in 6" :key ='index' ></NotificationLoader>
                    </ul>
                </template>
                <template v-else>
                    <h4 class="empty-message" v-show="isEmpty">{{ $t("no notification found")}}</h4>

                    <ul class="notification-list" v-show="!isEmpty">
                        <li class="notification-card" v-for="notification in notifications" :key="notification.id">
                            <div class="notification-icon">
                                <i class="eicon e-notification"></i>
                            </div>
                            <div class="notification-details">
                                <div class="notification">
                                    <a :href="notification.url">
                                        <p class="notification-title" v-text="$t(notification.title, notification.keys)"></p>
                                        <p class="notification-text" v-html="$t(notification.message, notification.keys)"></p>
                                        <a href="#"  @click.prevent="deleteNotification(notification.id)" class="erase-button ml-2">
                                            <i class="eicon e-delete"></i>
                                        </a>
                                    </a>
                                </div>
                                <div class="notification-time" :title="notification.date +' '+ notification.time">{{notification.posted_at }}</div>
                            </div>
                        </li>
                    </ul>

                </template>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
    import DashboardLayout from "../../layouts/DashboardLayout";
    import SettingMenu from "../../components/company/SettingSidebar";
    import client from "../../app/api/Client";
    import NotificationLoader from "../../components/_loaders/NotificationLoader";

    export default {
        components: {
            DashboardLayout,
            SettingMenu,
            NotificationLoader
        },
        data() {
            return {
                isContentLoading : false,
                notifications: []
            }
        },

        computed: {
            isEmpty() {
                return this.notifications.length === 0;
            }
        },
        methods: {
            async getNotifications() {
                this.isContentLoading = true;
                try {
                    let {data: {data}} = await client().get('user/notification');
                    this.notifications = data.notifications;
                    this.unread = data.count;

                } catch (e) {
                }
                this.isContentLoading = false;

            },
            deleteNotification(notification) {
                client().delete(`user/notification/${notification}/delete`)
                    .then(({data: {message}}) => {
                        this.$toast.success(this.$t(message));
                        this.notifications = this.notifications.filter((n)=> n.id !== notification );
                    }).catch(({response: {data}}) => {
                    this.$toast.error(data.message)
                });
            }
        },

        created() {
            this.getNotifications();
        }
    }
</script>
